import React, { useRef, useState } from "react";
import ProductHeader from "web/react/components/buybuybuy-area/product-header/product-header";
import { PricingCardPDP } from "web/react/components/pdp-pricing-card";
import SaveForLaterButton from "web/react/components/save-for-later-button/save-for-later-button";
import { HStack } from "web/react/emo/h-stack";
import { Price } from "web/react/emo/price";
import { Sticker } from "web/react/emo/sticker";
import { VStack } from "web/react/emo/v-stack";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { BuyButton } from "web/react/pages/product/in-stock/in-stock-product-page/buy-button";
import { ExpressCheckoutDisclaimer } from "web/react/pages/product/in-stock/in-stock-product-page/express-checkout-disclaimer/express-checkout-disclaimer";
import {
    ProductPurchaseType,
    ProductPurchaseTypes,
    useInStockProductPageContext,
} from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import { SizeArea } from "web/react/pages/product/in-stock/in-stock-product-page/size-area";
import { getBuyOptionStickerType } from "web/react/pages/product/in-stock/in-stock-product-page/utils";
import { ProductStatus } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";
import { canUseMembership } from "web/script/modules/utils";
import { ProductBuyOptionsSerializer } from "web/types/serializers";
import * as styles from "./product-info.css";

export function handleSizeExpanded(
    productPurchaseType: ProductPurchaseTypes,
    isExpanded: boolean,
    isComparison: boolean
): void {
    const action = isExpanded ? "close_size_picker" : "open_size_picker";
    analytics.event(
        isComparison ? "compare_prices_size_picker" : "size_picker",
        action,
        undefined,
        false,
        null,
        `${productPurchaseType}.size_picker.${action}`,
        null,
        undefined,
        true
    );
}

/**
 * @typedef ProductInfoProps
 * @description type to infer buy option if modal is present
 */
type ProductInfoProps =
    | {
          isModal: boolean;
          buyOption: ProductBuyOptionsSerializer;
      }
    | {
          isModal?: never;
          buyOption?: never;
      };

export function ProductInfo({
    isModal,
    buyOption: modalBuyOption,
}: ProductInfoProps): React.ReactElement {
    const { isMobileViewport, isTabletViewport, isDesktopViewport } = useDomViewport();
    const {
        designer,
        product,
        sizePicker: { sizes: contextSizes },
        activeBuyOption: { buyOption: activeBuyOption, promotion, index: activeBuyOptionIndex },
        cheapestBuyOptionId,
        activeProduct: { cardSizeList, shippingInformation, price, selectedSizeOption },

        isLoggedIn,
        productPurchaseType,
    } = useInStockProductPageContext();
    // set buy option depending on if the product info is on a regular PDP or in the checkout modal with the isModal flag
    const buyOption = isModal ? modalBuyOption : activeBuyOption;
    const buyOptionPrice = isModal
        ? {
              fullPrice: modalBuyOption.price,
              inStock: Boolean(modalBuyOption.in_stock),
              salePrice: modalBuyOption.sale_price,
          }
        : price;
    const buyOnStoreBtnRef = useRef<HTMLImageElement | null>(null);

    const [isSizePickerExpanded, setIsSizePickerExpanded] = useState(false);

    const isExpressCheckout = productPurchaseType === ProductPurchaseType.EXPRESS_CHECKOUT;

    const stickerType = getBuyOptionStickerType(product, activeBuyOption, cheapestBuyOptionId);

    const handleSizeLinkClick = (expanded: boolean, shouldSendAnalytic = true): void => {
        setIsSizePickerExpanded((isSizePickerExpanded) => !isSizePickerExpanded);

        if (isExpressCheckout) {
            shouldSendAnalytic && analytics.event("express_checkout", "select_box_clicked");
        } else {
            handleSizeExpanded(productPurchaseType, isSizePickerExpanded, false);
        }
    };

    const sizes = !!isModal ? buyOption.sizes : contextSizes;
    const buyOptionStatus: ProductStatus = !buyOption.in_stock
        ? "out_of_stock"
        : buyOption.on_sale
        ? "on_sale"
        : "in_stock";

    return (
        <VStack spacing="md">
            <VStack spacing={isMobileViewport || (isTabletViewport && isModal) ? "md" : "xl"}>
                <HStack justify="space-between">
                    <div data-testid="product-header">
                        <ProductHeader
                            variant="emotional"
                            isOwnerActive={designer.active}
                            isOwnerFeedLinkable={designer.active}
                            ownerName={designer.name}
                            designerUrl={designer.url}
                            shortDescription={product.short_description}
                            isModal={isModal}
                        />
                    </div>
                    {canUseMembership() && !isModal && (
                        <SaveForLaterButton
                            isLoggedIn={isLoggedIn}
                            type="icon"
                            variant="emotional"
                            productId={product.product_id}
                            productStatus={buyOptionStatus}
                            pageSource="pdp"
                            className={styles.saveForLaterBtn}
                        />
                    )}
                </HStack>

                <VStack spacing={isMobileViewport || (isTabletViewport && isModal) ? "sm" : "md"}>
                    <HStack justify={"space-between"}>
                        <Price
                            {...buyOptionPrice}
                            size={isDesktopViewport ? "lg" : isModal ? "xm" : "lg"}
                        />
                        {stickerType && <Sticker name={stickerType} />}
                    </HStack>
                    {isExpressCheckout && (
                        <ExpressCheckoutDisclaimer
                            productId={product.product_id}
                            buyOption={activeBuyOption}
                        />
                    )}
                    {sizes && (
                        <SizeArea
                            sizes={sizes}
                            isSizePickerExpanded={isSizePickerExpanded}
                            handleSizeLinkClick={handleSizeLinkClick}
                            isModal={!!isModal}
                        />
                    )}
                    <div data-testid="buy-button-product-info" ref={buyOnStoreBtnRef}>
                        <BuyButton
                            variant={buyOption.is_icon ? "checkout" : "affiliate"}
                            action="buy"
                            buyOptionPosition={activeBuyOptionIndex}
                            buyOption={buyOption}
                            sendExtraAnalytics={!isExpressCheckout}
                        />
                    </div>
                </VStack>
            </VStack>
            <VStack spacing="sm">
                <PricingCardPDP
                    retailerName={buyOption.retailer_name}
                    sizeList={cardSizeList}
                    shippingInformation={shippingInformation}
                    leadUrl={buyOption.affiliate_url}
                    isSizeSelected={selectedSizeOption !== null}
                    promotion={promotion}
                />
            </VStack>
        </VStack>
    );
}
